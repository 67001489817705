.prodListHeading {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 20px;
    text-align: center;
    font-family: Source Sans Pro;
}

.listMainBlock {
    width: 100%;
    .prodImage {
        width: 100%;
        height: 120px;
        box-shadow: 0px 3px 8px #00000029;
        border: 2px solid #ffffff;
        border-radius: 10px;
        opacity: 1;
    }
    .prodName {
        letter-spacing: 0px;
        color: #232530;
        text-transform: uppercase;
        opacity: 1;
        text-align: center;
        font-size: 10px;
        word-wrap: break-word;
        font-weight: 100;
        margin-top: 8px;
    }

    .custombadge {
        position: relative;
        top: 70px;
        left: -5px;
        box-shadow: 0px 4px 16px #455b6329;
        border-radius: 8px !important;
        opacity: 1;
        background: #ffffff;
        color: #232530;
    }
}

.prodDetailImage {
    width: 100%;
    height: 155px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
    opacity: 1;
}

.prodDetailText {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 10px;
    margin-top: 5px;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: bold;
}
.prodDetailprice {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
    .prodDetailpriceSpan {
        letter-spacing: 0px !important;
        color: #424242 !important;
        opacity: 1 !important;
        font-size: 11px !important;
        font-weight: normal !important;
        text-transform: none !important;
        margin-left: 10px;
    }
}

.prodBadgesCustom {
    background: #424242 !important;
}

.prodDetailDesc {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 11px !important;
    font-weight: normal !important;
    text-transform: none !important;
    word-wrap: break-word;
    margin-bottom: 10px;
}
.prodDetailtags {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 14px !important;
    font-weight: normal !important;
    text-transform: none !important;
    word-wrap: break-word;
    margin-bottom: 15px;
    text-transform: uppercase !important;
}
.prodDetailtHint {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-size: 12px;
    font-weight: bold;
}

.customButtonBuyNow {
    width: 100%;
    border: none;
    border-radius: 12px;
    opacity: 1;
    height: 50px;
    background: linear-gradient(to right, #f42351, #f86181, #fe9743);
    color: #ffffff;
    outline: 0;
    text-transform: uppercase;
    font-size: 15px;
    &:hover {
        color: #ffffff;
        outline: 0;
    }
    &:focus {
        color: #ffffff;
        outline: 0;
    }
}

.customButtonAddtoCart {
    width: 100%;
    border: none;
    border-radius: 12px;
    opacity: 1;
    height: 50px;
    background: #fe9743;
    color: #ffffff;
    outline: 0;
    text-transform: uppercase;
    font-size: 15px;
    &:hover {
        color: #ffffff;
        outline: 0;
    }
    &:focus {
        color: #ffffff;
        outline: 0;
    }
}

.customcounterDiv {
    width: 100%;
    border: none;
    border-radius: 12px;
    opacity: 1;
    height: 50px;
    background: #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: space-around;
    // color: #ffffff;
    // outline: 0;
    // text-transform: uppercase;
    // font-size: 15px;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    &.customizeDropDown {
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
            border: none !important;
        }
    }
}

.redgLink {
    font-size: 11px;
    cursor: pointer;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 15px;
    color: #424242;
    font-weight: bold;
}


.customizeGoogle {
    border: none !important;
    color: #F63451 !important;
    box-shadow: none !important;
    padding: 0 !important;
    font-size: 50px !important;
    line-height: 0 !important;
    background: none !important;
    margin-right: 40px;
    div {
        display: none;
    }
    span {
        padding: 0 !important;
    }
}

.customizeFacebook {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    line-height: 0 !important;
    background: none !important;
}

.productBox {
    display: flex;
    justify-content: space-around;
    column-count: 3;
    gap: 20px 14px;
    margin: 0 auto;
    flex-wrap: wrap;
    .productItem {
        width: 100px;
    }
}
.buttonList {
    button {
        background: rgb(235, 228, 228);
        color: #555;
        margin-right: 10px;
        font-size: 10px;
        padding: 2px 10px;
        border: 1px solid #bbb;
        min-width: 40px;
        &.activebtn {
            background: crimson;
            color: #fff;
            border: 1px solid crimson;
        }
        &:last-child {
           margin-right: 0px;
        }
    }
}
.activebtn {
    background: crimson;
    color: #fff;
}
.defaultbtn {
    background: darkgray;
    color: #000;
}

.productImageVideo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .productVideo {
        width: 45%;
        height: 150px;
        position: relative;
        video {
            width: 100%;
            height: 150px !important;
            object-fit: cover; // use "cover" to avoid distortion
            position: absolute;
        }
        svg {
            color: #fff;
            height: 45px;
            width: 45px;
            position: absolute;
            top: 33%;
            left: 37%;
            cursor: pointer;
        }
    }
    .prodDetailImage {
        width: 40%;
        height: 150px;
        border-radius: 10px;
        opacity: 1;
        img {
            height: 150px;
        }
    }
}