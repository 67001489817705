.chatBox {
  .messageBox {
    height: 295px;
    overflow-y: auto;
    display: flex;
    margin-bottom: 5px;
    flex-direction: column-reverse;
    
    .chatList {
      margin-bottom: 5px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .imgBlock {
        width: 30px;
        height: 25px;
        img {
          width: 25px;
          height: 25px;
          border-radius: 5px;
        }
      }
      .chatContent {
        h3 {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 3px;
          color: white;
          text-shadow: 1px 0px 4px black;
        }
        p {
          font-size: 10px;
          margin-bottom: 0px;
          color: white;
          text-shadow: 1px 0px 4px black;
        }
      }
    }
  }
  
  @media only screen and (max-width:600px){
    .messageBox::-webkit-scrollbar {
       display: none;
    }
    
    /* Track */
    .messageBox::-webkit-scrollbar-track {
      display: none;
    }
    
  }


  .hearts {
    svg {
      color: #f42351;
    }
    position: absolute;
    z-index: 10;
    width: 5%;
    min-width: 100px;
    height: 50%;
    min-height: 250px;
    .heart {
        height: 20%;
        min-height: 25px;
        width: 20%;
        min-width: 25px;
        margin-top: 200px;
        opacity: 0;
        animation: floating-heart 1s linear forwards;
    }
    .opacityHeart {
        opacity: 0;
    }
    @keyframes floating-heart {
        0% { 
            opacity: 1;
            transform: scale(0.3);
            transform: translate3d(0, 0, 0);
          }
          5% {
            opacity: 1;
            transform: scale(1.6);
          } 
          100% {
            opacity: 0;
            transform: scale(0);
            transform: translate3d(180px, -220px, 0);
          }
    }
  }
  .messageInputBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    input {
      width: 80%;
      height: 40px;
      border-radius: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      color: white;
    }
    button {
      width: 30px;
      height: 40px;
      min-width: 20px;
      border-radius: 0px;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      padding: 0px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      margin-top: 0px;
      
      &:focus {
        border: none;
      }
      svg {
        color: #f42351;
        margin-top: 0px;
        font-size: 30px;
      }
    }
  }
}
