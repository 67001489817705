.homeblock {
    // margin: 0px -12px 25px;
    height: auto;
    background-size: cover !important;
    position: relative;
    width: 100%;
//     filter: blur(8px);
//   -webkit-filter: blur(8px);
  backdrop-filter: saturate(180%) blur(50px);
  .bgWrapper {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.5); /* Black w/opacity/see-through */
    position: relative;
    height: auto;
    z-index: 2;
        .videoBlock {
            margin: auto;
            video {
                width: 100%;
                margin: auto;
                object-fit: cover; // use "cover" to avoid distortion
            }
        }
        .chatBlock {
            width: 300px;
            height: 350px;
            position: absolute;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            left: 10px;
            bottom: 35px;
            @media only screen and (max-width: 600px) {
                width: 250px;
                left: 10px;
                bottom: 25px;
            }
            // background: rgba(255, 255, 255, 0.9);
        }
        .proDuctListBlock {
            position: absolute;
            right: 20px;
            bottom: 35px;
            @media (max-width: 600px) {
                width: 95%;
            }
        }
        .profileMainBlock {
            position: absolute;
            left: 10px;
            top: 8px;
            .dataBlock {
                display: flex;
                align-items: center;
                .profileImage {
                    border-radius: 4px;
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }
                .profileBlock {
                    color: #fff;
                    h6 {
                        margin-bottom: 2px;
                        font-weight: 600;
                    }
                    p {
                        margin-bottom: 0px;
                        font-size: 12px;
                    }
                }
            }
            svg {
                color: #fff;
                cursor: pointer;
                margin-top: -6px;
                width: 20px;
                height: 20px;
            }
            .likesAndLiveuser {
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #ffffff;
                .LiveUsers {
                    display: flex;
                    align-items: center;
                    svg {
                        color: #3fecdc;
                    }
                }
                .totalLikes {
                    display: flex;
                    align-items: center;
                    svg {
                        color: #f42351;
                    }
                }
            }
        }

        .mainPageView {
            .expandIconDiv {
                position: absolute;
                right: 20px;
                bottom: 15px; 
                ul {
                    list-style: none;
                    margin: 0px;
                    padding: 0px;
                    
                    height: 415px;
                    display: flex;
                    align-items: flex-end;
                    flex-direction: column;
                    justify-content: space-between;

                    li {
                        border-radius: 3px;
                        &.darkBg {
                            background: rgba(0,0,0, 0.4);
                            padding: 1px 3px;
                            height: 24px;
                            width: 24px;
                        }
                        svg {
                            color: #fff;
                            cursor: pointer;
                            margin-top: -6px;
                            width: 18px;
                            height: 18px;
                        }
                        button {
                            padding: 5px 10px;
                            border-radius: 4px;
                            background: #f42351;
                        }
                    }
                }
            }

            .webinarInfoTile {
                color: #fff;
                position: absolute;
                left: 20px;
                bottom: 15px;  
                h5 {
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                h6 {
                    font-size: 12px;
                    font-weight: 400;
                }
            }

        }

        .videoControls {
            position: absolute;
            right: 20px;
            top: 30px;
            &.singleicon {
                right: 55px !important;
            }
            ul {
                list-style: none;
                margin: 0px;
                padding: 0px;
                li {
                    background: rgba(0,0,0, 0.4);
                    padding: 1px 3px;
                    height: 24px;
                    margin-bottom: 15px;
                    border-radius: 3px;
                    svg {
                        color: #fff;
                        cursor: pointer;
                        margin-top: -6px;
                        width: 22px;
                        height: 22px;
                    }
                }
            }
            @media only screen and (max-width: 600px) {
                top: 30%;
                right: 0px;
                ul {
                    
                    li {
                        background: rgba(61, 55, 55, 0.4);
                        margin-bottom: 0px;
                        border-radius: 0px;
                        padding: 18px 10px;
                        &:first-child {
                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                        }
                        &:last-child {
                            border-bottom-left-radius: 4px;
                            border-bottom-right-radius: 4px;
                        }
                        svg {
                            margin-top: -25px;
                        }
                    }
                }
            }
        }
    }
}

.commonFloatingBlock {
    width: 360px;
    height: 400px;
    background: white;
    // padding: 5px;
    border-radius: 12px;
    opacity: 1;
    @media (max-width: 600px) {
        width: auto;
    }
}

.headings {
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    font-size: 20px;
    font-weight: bold;
    
    .custombadge {
        background: #f42351 !important;
        border-radius: 16px !important;
        opacity: 1;
        letter-spacing: 0px;
        color: #fcfcf7;
        opacity: 1;
        font-size: 15px;
        font-weight: bold;
        margin-left: 10px;
        padding: 5px 15px;
        cursor: pointer;
    }
}

.listDiv {
    width: 100%;
    display: flex;
    overflow-x: auto;
    // gap: 25px;
    padding: 12px 0 0 10px;
    .cards-block {
        margin-right: 20px;
    }
}

.manageScroller::-webkit-scrollbar {
    height: 5px;
    @media (max-width: 600px) {
        width: 0px;
        height: 0px;
    }
}

.loader {
    background: url(../../assets/icons/loader.gif);
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
}

.customButton {
    // border-radius: 16px;
    opacity: 1;
    color: white;
    padding: 2px 8px;
    background: #f42351;
    // margin-left: 10px;
    margin-top: 0px;
    width: 100px;
    &:hover {
        color: white;
    }
    @media (max-width: 600px) {
        float: right;
        margin: 0;
        margin-right: 8px;
    }
}

.customPlusProduct {
    background: #fff;
    border-radius: 3px;
    position: absolute;
    top: 14px;
    right: -5px;
    height: 19px;
    padding: 1px;
    svg {
        margin-top: -10px;
        color: #111;
    }
}

.listMainBlock {
    width: 100px;
    .prodImage {
        width: 100%;
        height: 120px;
        box-shadow: 0px 3px 8px #00000029;
        border: 2px solid #ffffff;
        border-radius: 10px;
        opacity: 1;
        margin-bottom: 10px;
    }
    .prodName {
        letter-spacing: 0px;
        color: #232530;
        text-transform: uppercase;
        opacity: 1;
        text-align: center;
        font-size: 10px;
        word-wrap: break-word;
        font-weight: 100;
        margin-top: 8px;
    }

    .custombadge {
        position: relative;
        top: 70px;
        left: -5px;
        box-shadow: 0px 4px 16px #455b6329;
        border-radius: 8px !important;
        opacity: 1;
        background: #ffffff;
        color: #232530;
    }
    @media only screen and (max-width: 600px) {
        width: 60px;
        .prodImage {
            height: 80px;
        }
    }
}
video::-webkit-media-controls-fullscreen-button {
    display: none;
}
video::-webkit-media-controls-play-button {
    @media only screen and (max-width: 600px) {
        display: none;
    }
}
video::-webkit-media-controls-timeline {
    // @media only screen and (max-width: 600px) {
    //     display: none;
    // }
}
video::-webkit-media-controls-current-time-display{
    @media only screen and (max-width: 600px) {
        display: none;
    }
}
video::-webkit-media-controls-time-remaining-display {
    display: none;
}
video::-webkit-media-controls-mute-button {
    display: none;
    &:hover {
        display: none;
    }
}
video::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
}
video::-webkit-media-controls-volume-slider {
    display: none;
}

@media only screen and (max-width: 600px) {
    
}
.heartAnim{
    color: red;
    position: relative;
    left: -2%;
    animation: floatheart 1.9s ease-in-out 0s 1;
    opacity: 0;
}
@keyframes floatheart {
    0% {
      opacity: 1;
      transform: translateX(50px); 
    }
    50%{
      font-size: 34px;
    }
    100% {
      opacity: 0;
      transform: translateY(-550px);
    }
  }
  .opacityHeart {
    opacity: 0;
}

.hearts {
width: 5%;
min-width: 100px;
height: 85%;
min-height: 250px;
position: absolute;
z-index: 10;
}
.heart {
position: absolute;
height: 20%;
min-height: 25px;
width: 20%;
min-width: 25px;
background-color: red;
-webkit-mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>');
mask-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>');
-webkit-mask-repeat: no-repeat;
mask-repeat: no-repeat;
-webkit-mask-position: bottom;
mask-position: bottom;
opacity: 0;
animation: floating-heart 10s 1 cubic-bezier(0.5, 0.5, 0.5, 0.5);
}
@keyframes floating-heart {
0% {
opacity: 1;
bottom: 0%;
left: 0%;
}
10% {
opacity: 1;
bottom: 20%;
left: 70%;
}
20% { 
opacity: 1;
bottom: 40%;
left: 10%;
}
30% {
opacity: 1;
bottom: 60%;
left: 50%;
}
40% {
opacity: 1;
bottom: 80%;
left: 5%;
}
48% {
opacity: 1;
bottom: 100%;
left: 60%;
}
100% {
opacity: 0;
bottom: 100%;
left: 90%;
}
}
.heart:nth-child(1) {
animation-delay: 1s;
}
.heart:nth-child(2) {
animation-delay: 2.5s;
}
.heart:nth-child(3) {
animation-delay: 3.5s;
}
.heart:nth-child(4) {
animation-delay: 4.5s;
}